<template>
  <Modal
    variant="fullscreen"
    class="md:min-h-[695px] md:min-w-[1024px]"
    :style="modalStyle"
    :contentClass="showContent === Content.JoinWaitlist ? '!pt-0' : undefined"
    :show="show"
    :showClose="showContent === Content.ChangeRegion"
    @on-close="$emit('on-close')"
  >
    <!-- Modal Header -->
    <template v-if="showContent !== Content.JoinWaitlistSuccess" #header>
      <span v-if="showContent === Content.ChangeRegion">{{ $t('changeRegion.title') }}</span>

      <button
        v-if="showContent === Content.JoinWaitlist"
        type="button"
        @click="showContent = Content.ChangeRegion"
      >
        <Icon name="arrow-narrow-left-general" class="h-6px w-6px" />
      </button>
    </template>

    <!-- Modal Content -->
    <div
      v-if="showContent === Content.ChangeRegion"
      class="flex flex-col justify-between gap-10px md:flex-row"
    >
      <div
        v-for="(region, idx) in regions"
        :key="idx"
        class="flex w-full flex-col gap-6px sm:w-[206px]"
      >
        <div class="text-body-18-medium-mobile-px">{{ region.title }}</div>
        <div v-if="region.active" class="flex flex-col gap-4px">
          <button
            v-for="(item, idx) in region.active"
            :key="idx"
            class="w-fit"
            :class="
              item.code === locale
                ? 'text-detail-18-medium-px text-orange'
                : 'text-button-18-regular-mobile-px hover:text-orange'
            "
            type="button"
            @click="selectLanguage(item)"
          >
            {{ item.countryName }}
            <span
              class="text-detail-15-regular-mobile-px"
              :class="item.code !== locale && 'text-neutral-600'"
            >
              ({{ item.languageName }})
            </span>
          </button>
        </div>
        <div v-if="region.comingSoon" class="flex flex-col gap-4px">
          <p class="flex h-[18px] items-center text-detail-15-regular-mobile-px text-neutral-600">
            {{ $t('changeRegion.comingSoon') }}
          </p>
          <button
            v-for="(item, idx) in region.comingSoon"
            :key="idx"
            class="w-fit text-button-18-regular-mobile-px hover:text-orange"
            type="button"
            @click="selectCountry(region, item)"
          >
            {{ item }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="showContent === Content.JoinWaitlist">
      <div class="mb-8px max-w-[696px] md:mb-10px">
        <div class="mb-4px text-body-27-medium-px">{{ $t('joinWaitlist.title') }}</div>
        <i18n-t
          keypath="joinWaitlist.text"
          tag="p"
          class="text-body-18-regular-mobile-px text-neutral-800"
          scope="global"
        >
          <template #br><br /></template>
        </i18n-t>
      </div>
      <div class="md:max-w-[472px]">
        <div class="mb-8px md:mb-10px">
          <span class="mr-1px text-detail-18-medium-px">{{ $t('joinWaitlist.region') }}</span>
          <span class="text-button-18-regular-mobile-px text-neutral-800">
            {{ selectedJoinWaitlist?.region.title }}, {{ selectedJoinWaitlist?.country }}
          </span>
        </div>
        <form name="JoinWaitlistSubscription" novalidate="true" @submit.prevent="submitForm()">
          <FormInput
            v-model="state.email"
            classField="!mb-8px md:!mb-10px"
            :placeholder="$t('joinWaitlist.emailPlaceholder')"
            :label="$t('joinWaitlist.email')"
            type="email"
            :isError="v$.email.$error"
            :errorFixed="false"
            @change="v$.email.$touch"
          />
          <FormCheckbox
            v-model="state.consent"
            name="consent"
            classLabel="text-body-15-regular-px"
            :label="$t('joinWaitlist.consent')"
            :style="'white'"
            :isError="v$.consent.$error"
            @change="v$.consent.$touch"
          />
          <div class="mt-6px flex items-center gap-5px">
            <Button :label="$t('joinWaitlist.join')" color="orange" />
            <button
              class="text-button-18-regular-mobile-px"
              type="button"
              @click="$emit('on-close')"
            >
              {{ $t('joinWaitlist.later') }}
            </button>
          </div>
        </form>
      </div>
    </div>

    <div v-if="showContent === Content.JoinWaitlistSuccess" class="pt-5px md:pt-16px">
      <Picture v-if="!isMd" :data="imageData" class="mb-7px" loading="lazy" />
      <div class="mb-8px max-w-[696px] md:mb-10px">
        <div class="mb-4px text-body-27-medium-px">{{ $t('joinWaitlist.success.title') }}</div>
        <p class="text-body-18-regular-mobile-px text-neutral-800">
          {{ $t('joinWaitlist.success.text') }}
        </p>
      </div>
      <div class="md:max-w-[472px]">
        <div class="mb-8px md:mb-10px">
          <span class="mr-1px text-detail-18-medium-px">{{ $t('joinWaitlist.region') }}</span>
          <span class="text-button-18-regular-mobile-px text-neutral-800">
            {{ selectedJoinWaitlist?.region.title }}, {{ selectedJoinWaitlist?.country }}
          </span>
        </div>
        <Button
          variant="outline"
          :label="$t('joinWaitlist.success.close')"
          @click="$emit('on-close')"
        />
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { required, email, sameAs } from '@vuelidate/validators'
import screens from '#tailwind-config/theme/screens'
import locales, { getLanguageCodeFromLocaleCode } from '~/i18n/locales'
import { LocaleCode, Locale } from '~/types/locales'
import { FileField } from '~/types/generated'

import universalImage from '~/assets/images/content/universal.png'
import universalImageWebp from '~/assets/images/content/universal.webp'

import universalBackgroundImage from '~/assets/images/content/universal-background.webp'

const imageData = {
  width: 358,
  height: 247,
  responsiveImage: {
    srcSet: universalImage,
    webpSrcSet: universalImageWebp,
    alt: ''
  }
} as FileField

enum Content {
  ChangeRegion,
  JoinWaitlist,
  JoinWaitlistSuccess
}

type ExtendedLocale = Locale & { url: string }

interface Region {
  title: string
  active?: Array<ExtendedLocale>
  comingSoon?: Array<string>
}

interface ChangeRegionModalProps {
  show?: boolean
}

const props = defineProps<ChangeRegionModalProps>()

defineEmits(['on-close'])

const { t, locale } = useI18n()
const config = useRuntimeConfig()
const breakpoints = useBreakpoints(screens)
const isMd = breakpoints.greater('md')
const countryCode = useCountryCode()

const state = reactive({
  email: '',
  consent: false,
  listId: 28,
  attributes: {
    REGION: ''
  }
})

const rules = {
  email: { required, email },
  consent: { sameAs: sameAs(true) }
}

const v$ = useVuelidate(rules, state)

const regions = ref<Array<Region>>([
  {
    title: t('changeRegion.europe'),
    active: [
      // LocaleCode.DE_DE,
      // LocaleCode.EN_DE,
      LocaleCode.DE_CH,
      LocaleCode.FR_CH,
      LocaleCode.IT_CH,
      LocaleCode.EN_CH
    ]
      .map(locale => ({
        ...locales.find(l => l.code === locale),
        url: `${config.public.domains[countryCode]}/${locale}`
      }))
      .filter(Boolean) as Array<ExtendedLocale>,
    comingSoon: [
      t('changeRegion.germany'),
      t('changeRegion.austria'),
      t('changeRegion.france'),
      t('changeRegion.italy'),
      t('changeRegion.spain'),
      t('changeRegion.netherlands'),
      t('changeRegion.poland'),
      t('changeRegion.portugal'),
      t('changeRegion.greece')
    ]
  },
  {
    title: t('changeRegion.middleEast'),
    comingSoon: [t('changeRegion.unitedArabEmirates'), t('changeRegion.isreal')]
  },
  {
    title: t('changeRegion.asiaPacific'),
    comingSoon: [t('changeRegion.singapore')]
  },
  {
    title: t('changeRegion.africaCentral'),
    comingSoon: [
      t('changeRegion.southAfrica'),
      t('changeRegion.nigeria'),
      t('changeRegion.cameroon')
    ]
  }
])
const showContent = ref<Content>(Content.ChangeRegion)
const selectedJoinWaitlist = ref<{ region: Region; country: string }>()

const modalStyle = computed(() => ({
  background:
    isMd.value && showContent.value !== Content.ChangeRegion
      ? `url(${universalBackgroundImage}), white`
      : 'white'
}))

watch(
  () => props.show,
  () => {
    showContent.value = Content.ChangeRegion
  }
)

function selectLanguage(item: ExtendedLocale) {
  const lang = useCookie('nf_lang')
  lang.value = getLanguageCodeFromLocaleCode(item.code)
  location.href = item.url
}

function selectCountry(region: Region, country: string) {
  showContent.value = Content.JoinWaitlist
  selectedJoinWaitlist.value = { region, country }
  state.attributes.REGION = country
}

async function submitForm() {
  const isFormInvalid = await v$.value.$validate()

  if (!isFormInvalid) {
    return
  }

  const { data, error } = await useFetch('/.netlify/functions/brevo', {
    headers: { 'Content-type': 'application/json' },
    method: 'POST',
    body: toRaw(state)
  })

  if (!data.value) {
    // eslint-disable-next-line
    console.error(error.value)
  } else {
    showContent.value = Content.JoinWaitlistSuccess
  }
}
</script>
